<script>
import dayjs from 'dayjs'
import IconTalk from "@/assets/imgs/icons/admin/vog/fb-vog-talk-icon.svg";
import IconLive from "@/assets/imgs/icons/admin/vog/fb-vog-live-icon.svg";

export default {
  name: 'Notification',
  props: ['notification'],
  emits: ['closeMe', 'activate'],
  components: {
    IconTalk,
    IconLive
  },
  computed: {
    notificationType() {
      const content = this.notification.content[0]
      return content.text
        ? 'text'
        : content.media
          ? (content.media.originalFormat || content.media.originFormat || '').startsWith('audio')
            ? 'audio'
            : 'video'
          : 'unknown'
    },
    readed() {
      return this.notification.readAt.seconds !== 0
    },
    nTime() {
      return dayjs.unix(this.notification.createdAt.seconds).format('hh:mm A')
    }
  }
}
</script>

<template>
<div class="notification-wrapper d-flex" :class="{read: readed}">
  <div class="notification-body pa-5 flex-grow-1" @click.stop="$emit('activate')">
    <div v-if="notificationType === 'text'">
      <div class="content-text" :innerHTML="notification.content[0].text.content"/>
    </div>
    <div v-if="notificationType === 'audio' || notificationType === 'video'" class="d-flex">
      <div class="media-icon">
        <IconTalk class="audio" v-if="notificationType === 'audio'"/>
        <IconLive class="video" v-else/>
      </div>
      <div class="content-text">{{notification.content[0].title}}</div>
    </div>
    <div class="notification-time font-size-10 text-grey-2d">
      {{nTime}}
    </div>

  </div>
  <div class="notification-close">
    <v-btn
      flat
      icon="mdi-close"
      size="x-small"
      elevation="0"
      @click.stop="$emit('closeMe')"
    />
  </div>
</div>
</template>

<style lang="scss" scoped>

.notification-wrapper {
  transition: all .3s;
  cursor:pointer;

  &.read {
    color: rgb(var(--v-theme-grey-2d)) !important;
    svg {
      color: rgb(var(--v-theme-grey-2d)) !important;
    }
  }

  &:not(.read){
    &:hover{
      background-color: rgb(var(--v-theme-admin-grey-2))
    }
  }

  .notification-body {
    position: relative;

    .media-icon {
      width: 30px;
      svg {
        width: 20px;
        height: 20px;

        &.video {
         color: rgb(var(--v-theme-error))
        }
        &.audio {
         color: rgb(var(--v-theme-fb-navy-2))
        }
      }
    }

    .notification-time{
      position: absolute;
      bottom: 5px;
      right: 10px;
    }
  }
}

</style>
