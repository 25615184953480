<script>
import dayjs from 'dayjs'
import waitFor from '@/utils/waitFor'
import ChatInbox from '@/components/chat/ChatInbox'

// import { Scene } from '@/lib/enums'

import IconX from '@/assets/imgs/icons/icon-x.svg'
//import IconMessageSent from '@/assets/imgs/icons/icon-message-sent.svg'
import IconChat from '@/assets/imgs/icons/icon-chat.svg'
// import IconVideo from '@/assets/imgs/icons/icon-video.svg'

  export default{
    name: 'ChatWidget',
    components:{
      // IconVideo
      IconX,
      //IconMessageSent,
      IconChat,
      ChatInbox
    },
    props:{
      isOpen: {
        type: Boolean,
        default: false
      },
      admin: {
        type: Boolean,
        default: false
      }
    },
    data(){
      return{
        activeChat: null,
        currentMessageText: '',
        sending: false,
        error: false,
        search: null
      }
    },
    emits: ['chatboxClose'],
    methods:{
      lockUI() {
        if (!this.admin)
          this.$store.state.pc.app.fire('ui:open', true)
      },
      unlockUI() {
        if (!this.admin)
          this.$store.state.pc.app.fire('ui:open', false)
      },
   
      chatboxClose(){
        this.$emit('chatboxClose')
      },

      async sendMessage() {
        // console.log('sending', this.currentMessageText)
        this.sending = true
        let res = false
        if (this.activeChat === 'env') {
          res = await this.$store.dispatch('scene/sendMessage', [{ text: this.currentMessageText }] )
        } else {
          res = await this.$store.dispatch('user/dm/send', { to: this.activeChat, msg: [{ text: this.currentMessageText }] })
        }
        this.sending = false
        if (!res) {
          this.error = true
          await waitFor(4000)
          this.error = false
        } else {
          this.currentMessageText = ''
        }
      },

      openChat(chat) {
        this.activeChat = chat
        if (chat === 'env')
          return
        const dmid = this.$store.getters['user/dm/dmID'](chat)
        //console.log('openChat', chat, dmid)
        this.$store.commit('user/dm/setHasNew',{ id: dmid, next: false })
      },

      dmHasNew(uid) {
        const dmid = this.$store.getters['user/dm/dmID'](uid)
        return this.$store.state.user.dm.hasNew[dmid]
      }
    },
    computed: {
      onlineUsers() {
        return (this.$store.getters['collections/users/list'] || []).filter(u => {
          if (!u.approved)
            return false
          if (!u.lastActivity)
            return false
          if (!u.envLocation || !this.$store.state.user.data.envLocation || u.envLocation.scene !== this.$store.state.user.data.envLocation.scene)
            return false
          if (u.uid === this.$store.state.user.data.uid)
            return false
          const last = dayjs.unix(u.lastActivity.seconds)
          const now = dayjs()
          return now.diff(last,'s') < 60
        })
      },
      searchUsers() {
        if (this.search === null || this.search === '')
          return []
        return (this.$store.getters['collections/users/list'] || []).filter(u => {
          if (!u.approved)
            return false
          if (u.uid === this.$store.state.user.data.uid)
            return false
          const ustr = (u.displayName + ' ' + u.email).toLowerCase()
          return ustr.includes(this.search.toLowerCase())
        })
      },
      dms() {
        const dms = this.$store.getters['user/dm/list']
        const online = this.onlineUsers
        const ret = dms.filter(u => online.find(user => user.uid === u.uid) === -1)
        // console.log('dms/ret', dms, ret)
        return ret
      },
      envName() {
        return this.$t(`scenes.${this.$store.state.user.data.envLocation.scene}`)
      },
      chatName() {
        if (!this.activeChat) return ''
        if (this.activeChat !== 'env')
          return this.$store.getters['collections/users/byID'](this.activeChat).displayName
        return this.envName
      },
      messages() {
        if (this.activeChat === 'env')
          return this.$store.getters['scene/messages']
        return this.$store.getters['user/dm/messages'](this.activeChat)
      }
    },
    created() {
      //console.log(';;;;;', this.onlineUsers)
      this.$store.dispatch('user/dm/reload')
    },
    watch: {
      /*
      '$store.user.dm.data': {
        deep: true,
        handler() {
          this.$store.dispatch('user/dm/reload')
        }
      },
      */
      '$store.state.user.dm.hasNew': {
        deep: true,
        handler(next) {
          let doNotify = true
          if (this.activeChat && this.activeChat !== 'env') {
            const dmid = this.$store.getters['user/dm/dmID'](this.activeChat)
            //console.log('HAS ACTIVE', this.activeChat, next, dmid)
            if (next[dmid]) {
              doNotify = false
              //console.log('set readed', dmid)
              this.$store.commit('user/dm/setHasNew', {id : dmid, next: false})
            } else {
              doNotify = false

            }
          }
          if (doNotify) {
            this.$store.dispatch('user/dm/notifyNewMessage')
            //console.log('push notification!')
          }
          //console.log('hasNew updated in chat widget', next, prev)
        }
      }
    }
  }

</script>

<template>
  <div class="chat-widget" @mouseover="lockUI()" @mouseout="unlockUI()" v-if="isOpen">

    <div class="chat-widget-user-listing chat-widget-page" v-if="!activeChat">
      <div class="chat-widget-header">
        <h4 class="text-truncate font-size-18 mr-3">
          <span v-if="search === null">{{$t('chat.whoIsOnline.title')}}</span>
          <span v-else>{{$t('chat.whoIsOnline.search')}}</span>
        </h4>
        <div class="chat-widget-header-action ml-auto mr-n3">
          <button class="chat-widget-close" @click="search =  search === null ? '' : null">
            <v-icon>mdi-account-search-outline</v-icon>
          </button>
          <button class="chat-widget-close" @click="chatboxClose">
            <IconX />
          </button>
        </div>
      </div>
      <div v-if="search === null" class="chat-widget-body">
        <div class="chat-widget-body-content custom-scrollbar">
          <ul class="chat-widget-user-list">
            <li v-if="!admin" class="chat-widget-user-list-item clickable" @click="openChat('env')">
              <div class="chat-widget-user-list-item-name text-truncate mr-3">
                {{$t('chat.groupChat.title')}} [{{envName}}]
              </div>
            </li>
            <li class="chat-widget-user-list-item" v-if="!onlineUsers.length && !dms.length">
              <div class="chat-widget-user-list-item-name text-truncate mr-3">
                {{$t('chat.whoIsOnline.noOneOnline')}}
              </div>
            </li>
            <li class="chat-widget-user-list-item" v-for="u in onlineUsers" :key="u.uid" @click="openChat(u.uid)">
              <div :class="{'mark-new': dmHasNew(u.uid)}" class="avtar mr-3">
              </div>
              <div class="chat-widget-user-list-item-name text-truncate mr-3">
                {{u.displayName}}
              </div>
              <div class="chat-widget-user-list-item-action ml-auto">
                <!--
                <IconVideo class="mr-2" />
                -->
                <v-btn
                  icon
                  size="x-small"
                  flat
                  elevation="0"
                >
                  <IconChat />
                </v-btn>
              </div>
            </li>
            <li class="chat-widget-user-list-item" v-for="u in dms" :key="u.uid">
              <div :class="{'mark-new': dmHasNew(u.uid)}"  class="avtar mr-3">
              </div>
              <div class="chat-widget-user-list-item-name text-truncate mr-3">
                {{u.displayName}}
              </div>
              <div class="chat-widget-user-list-item-action ml-auto">
                <!--
                <IconVideo class="mr-2" />
                -->
                <v-btn
                  icon
                  size="x-small"
                  flat
                  elevation="0"
                  @click="activeChat = u.uid"
                >
                  <IconChat class="mr-n2" />
                </v-btn>
              </div>
            </li>

          </ul>
        </div>
      </div>
      <div v-else class="pa-2">
        <div class="position-relative">
          <v-btn
            flat 
            icon
            elevation="0"
            @click="search = null"
            class="search-close"
          >
            <IconX />
          </v-btn>

          <input
            type="text"
            class="search-input"
            v-model="search"
            @focus="lockUI"
            @blur="unlockUI"
            :placeholder="$t('chat.search')"
          />
        </div>
      </div>
            <li class="chat-widget-user-list-item" v-for="u in searchUsers" :key="u.uid">
              <div :class="{'mark-new': dmHasNew(u.uid)}" class="avtar mr-3">
              </div>
              <div class="chat-widget-user-list-item-name text-truncate mr-3">
                {{u.displayName}}
              </div>
              <div class="chat-widget-user-list-item-action ml-auto">
                <!--
                <IconVideo class="mr-2" />
                -->
                <v-btn
                  icon
                  size="x-small"
                  flat
                  elevation="0"
                  @click="openChat(u.uid); this.search = null"
                >
                  <IconChat class="mr-n2" />
                </v-btn>
              </div>
            </li>




    </div>
  
    <ChatInbox :messages="messages" :chatName="chatName" @backToList="activeChat = null" :activeChat="activeChat" v-if="activeChat" />

    <!--
    <div class="chat-widget-inbox chat-widget-page" v-if="activeChat">
      <div class="chat-widget-header">
        <v-icon dark left @click="activeChat = null">mdi-arrow-left</v-icon>
        <h4 class="text-truncate font-size-18 mr-3">{{chatName}}</h4>
        <div class="chat-widget-header-action ml-auto mr-n3">
            
        </div>
      </div>
      <div class="chat-widget-body">
        <div ref="msgWindow" class="chat-widget-body-content custom-scrollbar">
          
          {{messages}}
        </div>
        <div class="chat-widget-inbox-message-input-wrapper">
          <input
            v-model="currentMessageText"
            type="text"
            @focus="lockUI"
            @blur="unlockUI"
            @keyup.enter.stop="sendMessage"
            class="message-input"
            :disabled="sending"
            :class="{'bg-warning': error }"
          />
          <button
            :disabled="sending"
            @click="sendMessage"
            class="message-sent"
          >
            <v-icon v-if="sending" class="spinner">mdi-loading</v-icon>
            <IconMessageSent v-else />
          </button>
        </div>
      </div>
    </div>
    -->
  
  </div>
</template>

<style lang="scss">
.chat-widget{
  position: fixed;
  bottom:0;
  right: 20px;
  background-color: rgb(var(--v-theme-white));
  width: 350px;
  height: 100%;
  max-height: 450px;
  overflow: hidden;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  z-index: 101;
  &-page{
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
  }
  &-header{
    padding: 10px 20px;
    display: flex;
    align-items: center;
    background-color: rgb(var(--v-theme-fb-grey));
    color:rgb(var(--v-theme-white));
    &-action{
      display: flex;
      align-items: center;
      svg{
        display: block;
        width:28px;
        height: auto;
      }
    }
  }
  &-body{
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    &-content{
      flex-grow: 1;
      overflow-y: auto;
    }
  }
  &-user-list{
    &-item{
      display: flex;
      padding: 15px 20px;
      align-items: center;
      border-bottom:1px solid rgb(var(--v-theme-grey-200));
      cursor: pointer;
      &:hover{
        background-color:rgb(var(--v-theme-admin-grey));
      }
      .avtar{
        width: 35px;
        flex-shrink: 0;
        &.mark-new {
          //position: absolute;
          //top: 0;
          //right: 0;
          border: 2px solid #f00;
        }
      }
      &-action{
        flex-shrink: 0;
        display: flex;
        align-items: center;
        svg{
          width: 24px;
          height: auto;
          display: block;
        }
      }
    }
  }
  &-inbox{
    .chat-widget-body{
      background-color: rgb(var(--v-theme-admin-grey));
    }
    .chat-widget-body-content{
      padding-top: 8px;
      padding-bottom: 8px;
      display: flex;
      flex-direction: column-reverse;
      //justify-content: flex-end;
    }
  }
  .search-input {
    width: 100%;
    border-radius: 5px;
    font-size: 14px;
    padding: 4px;
    background: #fff;
  }
  .search-close {
    position: absolute;
    top: 4px;
    right: 8px;
    width: 16px;
    height: 16px;
  }
  .message-box-wrapper{
    padding: 8px 15px;
  }
  .message-box{
    padding: 8px 8px 20px 8px;
    position: relative;
    border-radius: 8px;
    background-color: rgb(var(--v-theme-white));
    box-shadow: 0 0 2px 1px rgba(0,0,0,0.3);
    width: 75%;
    background-color: #fff;
    .time-label{
      position: absolute;
      right:8px;
      font-size: 10px;
      bottom: 2px;
      color: rgb(var(--v-theme-link-2d));
    }
  }
  .chat-widget-inbox-message-input-wrapper{
    display: flex;
    align-items: flex-end;
    border-top: 1px solid rgb(var(--v-theme-fb-navy));
    background-color: rgb(var(--v-theme-white));
    .message-input{
      flex-grow:1;
      padding: 13px 20px;
      border-right: 1px solid rgb(var(--v-theme-fb-navy));
      &:focus{
        outline: 0 none;
      }
    }
    .message-sent{
      width:50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
