import genID from '@/utils/genID'
import file2buffer from '@/utils/file2buffer'
import { MIME, MediaStatus } from '@/lib/enums'
import * as Sentry from "@sentry/vue";
MIME['application/zip'] = 6
MIME[6] = 'application/zip'
//console.log(MIME)

const firebaseImport = () => import(/* webpackChunkName: "firebase" */ '@/firebase')

const uploadRef = (ref, buff, details, opts, progress) => new Promise((resolve, reject) => {
  // console.log('uploadRef ',{ details, opts })
  const task = ref.put(buff,{
    contentType: details.originalFormat,
    ...opts
  })
  task.on('state_changed', snap => {
    const progress = (snap.bytesTransferred / snap.totalBytes) * 100
    if (typeof progress === 'function')
      progress({
        details,
        percent: progress
      })
  }, err => {
    console.error('uploadRef ERROR', err)
    reject(err)
  }, () => {
    if (typeof progress === 'function')
      progress({
        details,
        percent: 100
      })
    // console.log('uploadRef COMPLETE')
    resolve()
  })
})

const fileUpload = async ({
  id,
  file,
  collection,
  buffer,
  storage,
  createdBy,
  refPrefix = '',
  refSuffix = '',
  //locale = 'en',
  percent = null
}) => {
  const fid = id || genID()
  // console.log('doUpload?', [file, collection, storage, refPrefix, process.env])
  if (!file || !collection) return

  const mimes = {
    [MIME['text/plain']]: 'text/(markdown|plain)',
    [MIME['text/csv']]: 'text/csv',
    [MIME['application/pdf']]: 'application/pdf',
    [MIME['image/webp']]: 'image/(bmp|gif|jpeg|png|webp)',
    [MIME['audio/webm']]: 'audio/(aac|mpeg|ogg|wav|webm)',
    [MIME['video/webm']]: 'video/(mp4|mpeg|ogg|webm)',
    [MIME['application/zip']]: 'application/zip'
  }
  const mimeLine = Object.values(mimes).find(m => {
    const rx = new RegExp(m)
    return rx.test(file.type)
  })
  if (!mimeLine)
    return { error: 'document.mime.unknown' }
  const mimeIndex = Object.values(mimes).indexOf(mimeLine)

  const refDoc = collection.doc(fid)

  const dbDoc = {}
  dbDoc.details = {
    filename: file.name,
    originalFormat: file.type,
    size: file.size,
    url: null
  }
  const now = new Date()
  dbDoc.MIME = mimeIndex
  dbDoc.createdBy = createdBy
  dbDoc.createdAt = now
  dbDoc.status = MediaStatus.Processing
  dbDoc.timestamp = now

  if (typeof percent === 'function')
    percent({
      details: dbDoc.details,
      percent: 0
    })

  const firebaseModule = await firebaseImport()
  const firebase = firebaseModule.default

  const filePath = `${refPrefix}${fid}${refSuffix}`
  let fileBuff = buffer;
  if(!fileBuff){
    fileBuff = await file2buffer(file)
  }
  const ref = storage 
   ? firebase.app().storage(`gs://${storage}`).ref(filePath)
   : firebase.storage().ref(filePath)

  try {
    const opts = {
      //contentLanguage: Locale[locale],
      customMetadata: {
        filename: file.name,
        createdBy: dbDoc.createdBy
      },
      cacheControl: 'public,max-age=31536000'
    }
    // console.log('uploading to', {storage, filePath, dbDoc, opts})
    await uploadRef(ref, fileBuff, dbDoc.details, opts, percent)
  } catch (err) {
    Sentry.captureException(err)
    console.log('uploading file error', err)
    return
  }

  await refDoc.set(dbDoc)
  // console.log('upload done')
  return fid
}

export default fileUpload
