<script>
import dayjs from 'dayjs'

export default {
  name: 'ChatMessage',
  props: ['message','dm'],
  computed: {},
  methods: {
    timestampToTime(time){
      return dayjs.unix(time).format('hh:mm A')
    },
    sender(uid) {
      return this.$store.getters['collections/users/byID'](uid).displayName
    }
  }
}
</script>

<template>
<div>
  <b>{{sender(message.user)}}</b><br/>
  {{message.content[0].text}}
  <span class="time-label">{{ timestampToTime(message.timestamp.seconds) }}</span>
</div>
</template>
