<script>
//import dayjs from 'dayjs'
import Notification from '@/components/notification/Notification'

import IconX from '@/assets/imgs/icons/icon-x.svg'
//import {Semantic} from "@/lib/enums"

  export default{
    name: 'NotificationWidget',
    components:{
      IconX,
      Notification
    },
    props:{
      isOpen: {
        type: Boolean,
        default: false
      }
    },
    emits: ['widgetClose','notification'],
    computed: {
      notificationList() {
        const list =  this.$store.getters['user/notification/list'] || []
        return list
          .sort((a,b) => {
            const as = a.createdAt.seconds
            const bs = b.createdAt.seconds
            return as === bs 
              ? 0
              : as > bs
                ? -1
                : 1
          })
      }
    },
    methods:{
      lockUI() {
        this.$store.state.pc.app.fire('ui:open', true)
      },
      unlockUI() {
        this.$store.state.pc.app.fire('ui:open', false)
      },
   
      widgetClose(){
        this.$emit('widgetClose')
      },

      async closeNotification(notification) {
        const coll = await this.$store.getters['user/notification/collection']()
        const ref = coll.doc(notification.id)
        await ref.delete()
        //console.log('close n', notification)
      },
      async activateNotification(notification) {
        this.$emit('notification', notification)
        // const type = this.notificationType(notification)
        // const coll = await this.$store.getters['user/notification/collection']()
        // const ref = coll.doc(notification.id)
        // await ref.update({readAt: new Date()})
        // if (type === 'text')
          // return
        //console.log('activate n', notification, type)
      },
      notificationType(notification) {
        const content = notification.content[0]
        return content.text
          ? 'text'
          : content.media
            ? (content.media.originalFormat || content.media.originFormat || '').startsWith('audio')
              ? 'audio'
              : 'video'
            : 'unknown'
      },
    }
  }

</script>

<template>
  <div v-click-outside="widgetClose" class="notification-widget d-flex flex-column" @mouseover="lockUI()" @mouseout="unlockUI()" v-if="isOpen">
    <div class="notification-widget-header">
      <h4 class="text-truncate font-size-18 mr-3">
        <span>{{$t('notification.widget.title')}}</span>
      </h4>
      <div class="notification-widget-header-action ml-auto mr-n3">
        <button class="notification-widget-close" @click="widgetClose">
          <IconX />
        </button>
      </div>
    </div>
    <div class="notification-widget-body flex-grow h-full overflow-y-auto custom-scrollbar">
      <div class="notification-widget-content">
        <div class="notification-list font-size-14">
          <Notification
            v-for="n of notificationList"
            :key="n.id"
            :notification="n"
            @closeMe="closeNotification(n)"
            @activate="activateNotification(n)"
          />
          <div class="pa-4" v-if="!notificationList.length">
          {{$t('notification.widget.noNotification')}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.notification-widget{
  z-index: 1;
  position: fixed;
  top: 70px;
  right: 90px;
  background-color: rgb(var(--v-theme-white));
  width: 350px;
  // height: 100%;
  max-height: 450px;
  overflow: hidden;
  border-radius: 6px;
  &-header{
    padding: 10px 20px;
    display: flex;
    align-items: center;
    background-color: rgb(var(--v-theme-fb-grey));
    color:rgb(var(--v-theme-white));
    &-action{
      display: flex;
      align-items: center;
      svg{
        display: block;
        width:28px;
        height: auto;
      }
    }
  }
  &-body{
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  &-content{
    flex-grow: 1;
    overflow-y: auto;
  }
}
/*
.notification-list{
  li{
    padding: 15px 20px 20px;
    position: relative;
    transition: all .3s;
    &:not(.read){
      cursor:pointer;
      &:hover{
        background-color: rgb(var(--v-theme-admin-grey-2))
      }
    }
    &+ li{
      border-top: 1px solid #ddd;
    }
    &:last-child{
      border-bottom: 1px solid #ddd;
    }
    .notification-time{
      position: absolute;
      bottom: 5px;
      right: 10px;
    }
  }
}
*/
</style>
