<script>

import dayjs from 'dayjs'
// import IconVideo from '@/assets/imgs/icons/icon-video.svg'
import IconMessageSent from '@/assets/imgs/icons/icon-message-sent.svg'
import Message from '@/components/chat/Message'

import waitFor from '@/utils/waitFor'

export default{
  name: 'ChatWidget',
  components:{
    IconMessageSent,
    Message
    // IconVideo
  },
  props:{
    messages: {
      required: true
    },
    chatName :{
      type: String,
      required: true
    },
    activeChat: {
      type: String,
      required: true
    }
  },
  emits:['backToList'],
  data(){
    return{
      currentMessageText: '',
      sending: false,
      users: null,
      error: false
    }
  },
  created(){
    this.users = this.usersList;
  },
  computed:{
    usersList() {
      const list = this.$store.getters['admin/users/list']
      return list
    },
  },
  methods:{
    lockUI() {
      this.$store.state.pc.app.fire('ui:open', true)
    },
    unlockUI() {
      this.$store.state.pc.app.fire('ui:open', false)
    },
    backToList(){
      this.$emit('backToList')
    },
    timestampToTime(time){
      return dayjs.unix(time).format('HH:mm')
    },
    async sendMessage() {
      //console.log('sending', this.currentMessageText)
      this.sending = true
      let ret = false
      if (this.activeChat === 'env') {
        ret = await this.$store.dispatch('scene/sendMessage', [{ text: this.currentMessageText }] )
      } else {
        ret = await this.$store.dispatch('user/dm/send',{
          to: this.activeChat,
          msg: [{ text: this.currentMessageText }]
        })
      }
      this.sending = false
      if (!ret) {
        this.error = true
        await waitFor(500)
        this.error = false
      } else {
        this.currentMessageText = ''
      }
    }
  }
}

</script>

<template>
  <div class="chat-widget-inbox chat-widget-page" >
      <div class="chat-widget-header">
        <v-icon dark left @click="backToList">mdi-arrow-left</v-icon>
        <h4 class="text-truncate font-size-18 mr-3">{{chatName}}</h4>
        <div class="chat-widget-header-action ml-auto mr-n3">
            <!--
          <button class="chat-widget-close">
            <IconVideo class="mr-2" />
          </button>
            -->
        </div>
      </div>
      <div class="chat-widget-body">
        <div ref="msgWindow" class="chat-widget-body-content custom-scrollbar">
          <div class="message-box-wrapper" v-for="message in messages" :key="message.id">
            <div class="message-box word-break-all" :class="this.$store.state.user.data?.uid === message.user ? 'ml-auto' : ''">
              <Message :message="message" :dm="activeChat !== 'env'" />
            </div>
          </div>
        </div>
        <div class="chat-widget-inbox-message-input-wrapper">
          <input
            v-model="currentMessageText"
            type="text"
            @focus="lockUI"
            @blur="unlockUI"
            @keyup.enter.stop="sendMessage"
            class="message-input"
            :disabled="sending"
            :class="{ 'bg-warning': error }"
          />
          <button
            :disabled="sending"
            @click="sendMessage"
            class="message-sent"
          >
            <v-icon v-if="sending" class="spinner">mdi-loading</v-icon>
            <IconMessageSent v-else />
          </button>
        </div>
      </div>
    </div>
</template>
